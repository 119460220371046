var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [_vm._v("Wi-Fi Settings")]),
      _c(
        "el-form",
        {
          staticClass: "form-settings",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Wi-Fi Connection" } },
            [
              _c("el-switch", {
                attrs: { "active-text": "On", "inactive-text": "Off" },
                model: {
                  value: _vm.wifiEnable,
                  callback: function($$v) {
                    _vm.wifiEnable = $$v
                  },
                  expression: "wifiEnable"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "500px" }, attrs: { label: "SSID" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { filterable: "", placeholder: "Select SSID" },
                  model: {
                    value: _vm.wifi.ssid,
                    callback: function($$v) {
                      _vm.$set(_vm.wifi, "ssid", $$v)
                    },
                    expression: "wifi.ssid"
                  }
                },
                _vm._l(_vm.wifi.ssidList, function(ssid, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: ssid.name, value: ssid.ssid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "500px" },
              attrs: { label: "Password Type" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { filterable: "", placeholder: "Select Type" },
                  model: {
                    value: _vm.wifi.passwordType,
                    callback: function($$v) {
                      _vm.$set(_vm.wifi, "passwordType", $$v)
                    },
                    expression: "wifi.passwordType"
                  }
                },
                _vm._l(_vm.passwordTypes, function(type, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: type, value: type }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "400px" }, attrs: { label: "Password" } },
            [
              _c("el-input", {
                attrs: { type: "password", placeholder: "Type a password" },
                model: {
                  value: _vm.wifi.password,
                  callback: function($$v) {
                    _vm.$set(_vm.wifi, "password", $$v)
                  },
                  expression: "wifi.password"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
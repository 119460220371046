var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.network.ethernet-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.network.save")))
              ]
            )
          : _vm._e()
      ]),
      _c(
        "el-form",
        {
          staticClass: "form-settings",
          attrs: {
            model: _vm.formData,
            "label-position": "left",
            "label-width": "250px"
          }
        },
        [
          _vm.ethernet.eth1.exists
            ? _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "500px" },
                          attrs: {
                            label: _vm.$t("settings.network.prefered-interface")
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t(
                                  "settings.network.select-netmask"
                                )
                              },
                              model: {
                                value: _vm.ethernet.preferredInternetInterface,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ethernet,
                                    "preferredInternetInterface",
                                    $$v
                                  )
                                },
                                expression:
                                  "ethernet.preferredInternetInterface"
                              }
                            },
                            _vm._l(["eth0", "eth1"], function(iface) {
                              return _c("el-option", {
                                key: iface,
                                attrs: { label: iface, value: iface }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("hr")
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 50 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-alert",
                    {
                      attrs: {
                        closable: false,
                        type: _vm.isAvailableEth0 ? "success" : "error"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-weight": "bolder" },
                          attrs: { slot: "title" },
                          slot: "title"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "ETH0 ( " + _vm.ethernet.eth0.macAddress + " )"
                            )
                          )
                        ]
                      ),
                      !_vm.isAvailableEth0
                        ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                            _vm._v("Ethernet cable unplugged")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("settings.network.mode") } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ethernet.eth0.ipAssignment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ethernet.eth0,
                                    "ipAssignment",
                                    $$v
                                  )
                                },
                                expression: "ethernet.eth0.ipAssignment"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "STATIC" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.network.static"))
                                  )
                                ]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "DHCP" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.network.dhcp"))
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("settings.network.ip-address")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.ethernet.eth0.ipAssignment === "DHCP",
                              type: "text",
                              placeholder:
                                _vm.ethernet.eth0.ipAssignment === "DHCP"
                                  ? ""
                                  : "192.168.1.2"
                            },
                            model: {
                              value: _vm.ethernet.eth0.ipAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.ethernet.eth0, "ipAddress", $$v)
                              },
                              expression: "ethernet.eth0.ipAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "500px" },
                          attrs: { label: _vm.$t("settings.network.netmask") }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled:
                                  _vm.ethernet.eth0.ipAssignment === "DHCP",
                                filterable: "",
                                placeholder: _vm.$t(
                                  "settings.network.select-netmask"
                                )
                              },
                              model: {
                                value: _vm.ethernet.eth0.netmask,
                                callback: function($$v) {
                                  _vm.$set(_vm.ethernet.eth0, "netmask", $$v)
                                },
                                expression: "ethernet.eth0.netmask"
                              }
                            },
                            _vm._l(_vm.netmaskList, function(netmask, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: netmask.title,
                                  value: netmask.value
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("settings.network.gateway") }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.ethernet.eth0.ipAssignment === "DHCP",
                              type: "text",
                              placeholder:
                                _vm.ethernet.eth0.ipAssignment === "DHCP"
                                  ? ""
                                  : "192.168.1.1"
                            },
                            model: {
                              value: _vm.ethernet.eth0.gateway,
                              callback: function($$v) {
                                _vm.$set(_vm.ethernet.eth0, "gateway", $$v)
                              },
                              expression: "ethernet.eth0.gateway"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("settings.network.dns-one") }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.ethernet.eth0.ipAssignment === "DHCP",
                              type: "text",
                              placeholder:
                                _vm.ethernet.eth0.ipAssignment === "DHCP"
                                  ? ""
                                  : "8.8.8.8"
                            },
                            model: {
                              value: _vm.ethernet.eth0.dns1,
                              callback: function($$v) {
                                _vm.$set(_vm.ethernet.eth0, "dns1", $$v)
                              },
                              expression: "ethernet.eth0.dns1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("settings.network.dns-two") }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.ethernet.eth0.ipAssignment === "DHCP",
                              type: "text",
                              placeholder:
                                _vm.ethernet.eth0.ipAssignment === "DHCP"
                                  ? ""
                                  : "8.8.4.4"
                            },
                            model: {
                              value: _vm.ethernet.eth0.dns2,
                              callback: function($$v) {
                                _vm.$set(_vm.ethernet.eth0, "dns2", $$v)
                              },
                              expression: "ethernet.eth0.dns2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.ethernet.eth1.exists
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-alert",
                        {
                          attrs: {
                            closable: false,
                            type: _vm.isAvailableEth1 ? "success" : "error"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-weight": "bolder" },
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  "ETH1 ( " +
                                    _vm.ethernet.eth1.macAddress +
                                    " )"
                                )
                              )
                            ]
                          ),
                          !_vm.isAvailableEth1
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "5px" } },
                                [_vm._v("Ethernet cable unplugged")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.isAvailableEth1
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.mode")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.ethernet.eth1.ipAssignment)
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.ip-address")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ethernet.eth1.ipAddress))
                                  ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.netmask")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.netmaskList.find(function(mask) {
                                          return (
                                            mask.value ==
                                            _vm.ethernet.eth1.netmask
                                          )
                                        }).title
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.gateway")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ethernet.eth1.gateway))
                                  ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.dns-one")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ethernet.eth1.dns1))
                                  ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("settings.network.dns-two")
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ethernet.eth1.dns2))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
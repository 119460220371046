<template>
  <div class="layout-settings">

    <div class="form-title">Wi-Fi Settings</div>

    <el-form :model="formData" label-position="right" label-width="150px" class="form-settings">

      <el-form-item label="Wi-Fi Connection">
        <el-switch active-text="On" inactive-text="Off" v-model="wifiEnable"></el-switch>
      </el-form-item>

      <el-form-item label="SSID" style="width: 500px">
        <el-select v-model="wifi.ssid" filterable placeholder="Select SSID" style="width: 200px">
          <el-option v-for="(ssid, index) in wifi.ssidList" :key="index" :label="ssid.name" :value="ssid.ssid"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Password Type" style="width: 500px">
        <el-select v-model="wifi.passwordType" filterable placeholder="Select Type" style="width: 200px">
          <el-option v-for="(type, index) in passwordTypes" :key="index" :label="type" :value="type"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="Password" style="width: 400px">
        <el-input type="password" v-model="wifi.password" placeholder="Type a password"></el-input>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
  export default {
    name: "WifiSettings",

    data: function(){
      return {
        passwordTypes: ["None", "WEP", "WPA2", "WPA/WPA2"],
        wifiEnable: false
      }
    },

    props: {
      wifi: {}
    }
  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>
<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.network.ethernet-settings') }} <span v-if="showSaveButton"
        style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.network.save')
        }}</span></div>

    <el-form :model="formData" label-position="left" label-width="250px" class="form-settings">


      <el-row :gutter="50" v-if="ethernet.eth1.exists">
        <el-col :span="24">

          <el-form-item :label="$t('settings.network.prefered-interface')" style="width: 500px">
            <el-select v-model="ethernet.preferredInternetInterface" filterable
              :placeholder="$t('settings.network.select-netmask')" style="width: 200px">
              <el-option v-for="iface in ['eth0', 'eth1']" :key="iface" :label="iface" :value="iface"></el-option>
            </el-select>
          </el-form-item>


          <hr>

        </el-col>
      </el-row>



      <el-row :gutter="50">
        <el-col :span="12">

          <el-alert :closable="false" :type="isAvailableEth0 ? 'success' : 'error'">
            <div style="font-weight: bolder" slot="title">{{ 'ETH0 ( ' + ethernet.eth0.macAddress + ' )' }}</div>
            <div v-if="!isAvailableEth0" style="margin-top: 5px">Ethernet cable unplugged</div>
          </el-alert>

          <div style="margin-top: 10px">

            <el-form-item :label="$t('settings.network.mode')">
              <el-radio-group v-model="ethernet.eth0.ipAssignment">
                <el-radio-button label="STATIC">{{ $t('settings.network.static') }}</el-radio-button>
                <el-radio-button label="DHCP">{{ $t('settings.network.dhcp') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item :label="$t('settings.network.ip-address')">
              <el-input :disabled="ethernet.eth0.ipAssignment === 'DHCP'" type="text" v-model="ethernet.eth0.ipAddress"
                :placeholder="ethernet.eth0.ipAssignment === 'DHCP' ? '' : '192.168.1.2'"></el-input>
            </el-form-item>

            <el-form-item :label="$t('settings.network.netmask')" style="width: 500px">
              <el-select :disabled="ethernet.eth0.ipAssignment === 'DHCP'" v-model="ethernet.eth0.netmask" filterable
                :placeholder="$t('settings.network.select-netmask')" style="width: 200px">
                <el-option v-for="(netmask, index) in netmaskList" :key="index" :label="netmask.title"
                  :value="netmask.value"></el-option>
              </el-select>
            </el-form-item>


            <el-form-item :label="$t('settings.network.gateway')">
              <el-input :disabled="ethernet.eth0.ipAssignment === 'DHCP'" type="text" v-model="ethernet.eth0.gateway"
                :placeholder="ethernet.eth0.ipAssignment === 'DHCP' ? '' : '192.168.1.1'"></el-input>
            </el-form-item>

            <el-form-item :label="$t('settings.network.dns-one')">
              <el-input :disabled="ethernet.eth0.ipAssignment === 'DHCP'" type="text" v-model="ethernet.eth0.dns1"
                :placeholder="ethernet.eth0.ipAssignment === 'DHCP' ? '' : '8.8.8.8'"></el-input>
            </el-form-item>

            <el-form-item :label="$t('settings.network.dns-two')">
              <el-input :disabled="ethernet.eth0.ipAssignment === 'DHCP'" type="text" v-model="ethernet.eth0.dns2"
                :placeholder="ethernet.eth0.ipAssignment === 'DHCP' ? '' : '8.8.4.4'"></el-input>
            </el-form-item>

          </div>

        </el-col>

        <el-col :span="12" v-if="ethernet.eth1.exists">

          <el-alert :closable="false" :type="isAvailableEth1 ? 'success' : 'error'">
            <div style="font-weight: bolder" slot="title">{{ 'ETH1 ( ' + ethernet.eth1.macAddress + ' )' }}</div>
            <div v-if="!isAvailableEth1" style="margin-top: 5px">Ethernet cable unplugged</div>
          </el-alert>

          <div v-if="isAvailableEth1" style="margin-top: 10px">

            <el-form-item :label="$t('settings.network.mode')">
              <span>{{ ethernet.eth1.ipAssignment }}</span>
            </el-form-item>

            <el-form-item :label="$t('settings.network.ip-address')">
              <span>{{ ethernet.eth1.ipAddress }}</span>
            </el-form-item>

            <el-form-item :label="$t('settings.network.netmask')">
              <span>{{ netmaskList.find(mask => mask.value == ethernet.eth1.netmask).title }}</span>
            </el-form-item>



            <el-form-item :label="$t('settings.network.gateway')">
              <span>{{ ethernet.eth1.gateway }}</span>
            </el-form-item>

            <el-form-item :label="$t('settings.network.dns-one')">
              <span>{{ ethernet.eth1.dns1 }}</span>
            </el-form-item>

            <el-form-item :label="$t('settings.network.dns-two')">
              <span>{{ ethernet.eth1.dns2 }}</span>
            </el-form-item>

          </div>


        </el-col>

      </el-row>


    </el-form>

  </div>
</template>

<script>
import { initializeWebSocket } from "@/utils/socket-utils"
export default {
  name: "EthernetSettings",

  data: function () {
    return {
      ethernet: {},
      netmaskList: [
        { title: "255.255.255.255", value: 32 },
        { title: "255.255.255.254", value: 31 },
        { title: "255.255.255.252", value: 30 },
        { title: "255.255.255.248", value: 29 },
        { title: "255.255.255.240", value: 28 },
        { title: "255.255.255.224", value: 27 },
        { title: "255.255.255.192", value: 26 },
        { title: "255.255.255.128", value: 25 },
        { title: "255.255.255.0", value: 24 },
        { title: "255.255.254.0", value: 23 },
        { title: "255.255.252.0", value: 22 },
        { title: "255.255.248.0", value: 21 },
        { title: "255.255.240.0", value: 20 },
        { title: "255.255.224.0", value: 19 },
        { title: "255.255.192.0", value: 18 },
        { title: "255.255.128.0", value: 17 },
        { title: "255.255.0.0", value: 16 },
        { title: "255.254.0.0", value: 15 },
        { title: "255.252.0.0", value: 14 },
        { title: "255.248.0.0", value: 13 },
        { title: "255.240.0.0", value: 12 },
        { title: "255.224.0.0", value: 11 },
        { title: "255.192.0.0", value: 10 },
        { title: "255.128.0.0", value: 9 },
        { title: "255.0.0.0", value: 8 },
        { title: "254.0.0.0", value: 7 },
        { title: "252.0.0.0", value: 6 },
        { title: "248.0.0.0", value: 5 },
        { title: "240.0.0.0", value: 4 },
        { title: "224.0.0.0", value: 3 },
        { title: "192.0.0.0", value: 2 },
        { title: "128.0.0.0", value: 1 },
      ],

      showSaveButton: false
    }
  },

  computed: {
    isAvailableEth0: function () {
      return this.ethernet.eth0.enable && this.ethernet.eth0.pluggedIn
    },
    isAvailableEth1: function () {
      return this.ethernet.eth1.enable && this.ethernet.eth1.pluggedIn
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },


  watch: {
    ethernet: {
      handler: function (val) {
        this.showSaveButton = true

      },
      deep: true
    },
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    onSave() {
      this.$store.dispatch("saveNetworkSettings", this.ethernet).then(response => {

        this.$message({
          type: "success", message: this.$t(response.message), onClose: () => {
            this.$notify({
              title: 'Warning',
              message: this.$t('settings.network.try-reconnecting-msg'),
              type: 'warning',
              duration: 0
            });
          }
        })
        this.showSaveButton = false


      }).catch(reason => {
        this.$message({ type: "error", message: this.$t(reason) })
        this.showSaveButton = false
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getNetworkSettings");
        this.ethernet = { ...this.$store.getters.ethernetSettings }
      } catch (error) {
        console.log(error)
      }
    }
  },


  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }

}
</script>

<style scoped>@import "../../css/general.css";</style>
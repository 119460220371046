<template>
  <div>
    <app-navigation :title="$t('network')"></app-navigation>
    <ethernet-settings></ethernet-settings>
    <!--<wifi-settings :wifi="wifi" style="margin-top: 30px"></wifi-settings>-->
  </div>

</template>

<script>
  import EthernetSettings from './components/EthernetSettings'
  import WifiSettings from './components/WifiSettings'
  import Navigation from '@/components/Navigation';

  export default {
    name: "Network",

    components: {
      'app-navigation': Navigation,
      ethernetSettings: EthernetSettings,
      wifiSettings: WifiSettings,
    },

    data: function () {
      return {
        ethernet: [
          {
            enable: true,
            name: "eth0",
            mode: "STATIC", //STATIC or DHCP
            ipAddress: "192.168.206.24",
            netmask: "255.255.255.0",
            gateway: "192.168.1.1",
            dns1: "8.8.8.8",
            dns2: "8.8.4.4"
          },

          {
            enable: false,
            name: "eth1",
            mode: "DHCP", //STATIC or DHCP
            ipAddress: "192.168.206.24",
            netmask: "255.255.255.0",
            gateway: "192.168.1.1",
            dns1: "8.8.8.8",
            dns2: "8.8.4.4"
          }
        ],

        wifi: {
          enable: true,
          ssidList: [
            { name: "NartMLN", ssid: "1" },
            { name: "CoreSmartHome", ssid: "2" },
            { name: "ZeNN", ssid: "3" },
            { name: "SuperOnline", ssid: "4" },
            { name: "Turk Telekom", ssid: "5" },
          ],
          passwordType: "",
          password: ""
        }
      }
    }
  }
</script>

<style scoped>

</style>